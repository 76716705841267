import { component, onRoute } from '@loom-js/core';

import { cartToggle } from '@app/activities/cart-toggle';
import { menuActiveState } from '@app/activities/menu-active-state';
import { SiteNav } from '@app/components/containers/site-nav';
import { ContentfulImage, Link } from '@app/components/simple';
import { MenuCta } from '@app/components/styled/buttons/menu-cta';
import { SearchCta } from '@app/components/styled/buttons/search-cta';
import { ShoppingBagCta } from '@app/components/styled/buttons/shopping-bag-cta';
import { mediaUrls } from '@app/helpers/contentful/media-urls';

import styles from './styles.scss';

export const LayoutHeader = component((html) => {
    // Menu activation activity
    const {
        effect: menuEffect,
        update: toggleMenu,
        value: menuState
    } = menuActiveState;
    const { update: toggleCart } = cartToggle;
    const onMenuControlClick = () => toggleMenu(!menuState());

    return html`
        <header class=${styles.layoutHeader}>
            <div class=${styles.toolbar}>
                ${Link({
                    children: ContentfulImage({
                        description: 'Hour Nutrition Home',
                        height: 39,
                        sources: [
                            {
                                // media: mediaQuery(minWidth('md')),
                                srcSet: mediaUrls['hour-nutrition-logo']({
                                    format: 'webp',
                                    width: 400
                                }),
                                type: 'image/webp'
                            }
                        ],
                        url: mediaUrls['hour-nutrition-logo']({
                            format: 'png',
                            width: 400
                        }),
                        width: 400
                    }),
                    className: styles.siteLogo,
                    href: '/',
                    onClick: onRoute
                })}
                <div class=${styles.controls}>
                    ${ShoppingBagCta({
                        aria: { label: 'Cart' },
                        onClick: () => toggleCart(true)
                    })}
                    ${SearchCta}
                    ${menuEffect(({ value }) =>
                        MenuCta({
                            active: value,
                            aria: { label: 'Menu' },
                            onClick: onMenuControlClick
                        })
                    )}
                </div>
            </div>
            ${SiteNav({
                className: styles.siteNav
            })}
        </header>
    `;
});
