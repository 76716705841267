import { activity } from '@loom-js/core';

import { modals } from '@app/activities/modals';
import { StandardModal } from '@app/components/containers/content/modals/standard-modal/standard-modal';
import {
    CartItem,
    CartItemProps
} from '@app/components/product/styled/cart-item';
import { ProductVariant } from '@app/types/shopify';

import { AddToCartFooter, AddToCartFooterProps } from './add-to-cart-footer';
import styles from './styles.scss';

export const AddToCartModal = () => {
    const modalId = 'add-to-cart';
    const defaultQuantity = 1;
    const { effect: itemQuantityEffect, update: updateQuantity } =
        activity(defaultQuantity);
    const { value: modalsValue, watch: watchModals } = modals;

    watchModals(
        () =>
            modalsValue()[modalId] === undefined &&
            updateQuantity(defaultQuantity)
    );

    return StandardModal<CartItemProps & ProductVariant, AddToCartFooterProps>({
        body: ({ priceV2, ...props }) =>
            CartItem({
                ...props,
                className: styles.addToCartModalBody,
                quantityMin: 1,
                onQuantityUpdate: (quantity) => updateQuantity(quantity),
                price: `$${priceV2?.amount}`
            }),
        className: styles.addToCartModal,
        footer: (props) =>
            AddToCartFooter({ ...props, itemQuantityEffect, modalId }),
        id: modalId
    });
};
