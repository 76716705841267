import {
    component,
    ContextFunction,
    MouseEventListener,
    TemplateTagValue
} from '@loom-js/core';
import classNames from 'classnames';

import styles from './styles.scss';

export interface DrawerProps {
    className?: string;
    closeButton: ContextFunction;
    contents: TemplateTagValue;
    isActive: boolean;
    onOutsideClick?: MouseEventListener;
}

export const Drawer = component<DrawerProps>(
    (
        html,
        { className, closeButton, contents, isActive, node, onOutsideClick }
    ) => html`
        <div
            $click=${(e: Event) =>
                (node() as Node).isSameNode(e.target as Node) &&
                onOutsideClick &&
                onOutsideClick(e)}
            class=${classNames(
                {
                    [styles.active]: isActive
                },
                className,
                styles.drawer
            )}
        >
            ${closeButton} ${contents}
        </div>
    `
);
