// import { activity, MouseEventListener } from '@loom-js/core';
import {
    AdaInput,
    AdaInputProps,
    InputType
} from '@app/components/simple/form-controls';
import { valueInRange } from '@app/helpers/number/value-in-range';

export interface NumberInputProps extends AdaInputProps {
    value?: number;
}

export const NumberInput = ({
    max,
    min = 0,
    onInput,
    inputRef,
    value,
    ...props
}: NumberInputProps) =>
    AdaInput({
        ...props,
        max,
        min,
        onInput: onInput,
        inputRef,
        type: InputType.Number,
        value: valueInRange(value, { min, max })
    });
