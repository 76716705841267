import classNames from 'classnames';

import { Button, ButtonProps } from '@app/components/simple';

import styles from './styles.scss';

export type { ButtonProps };

export const StandardButton = ({ className, ...props }: ButtonProps) =>
    Button({
        ...props,
        className: classNames(className, styles.standardButton)
    });
