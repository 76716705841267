import { cartConfig } from '@app/helpers/shopify/config';
import { LocalCartInput, LocalCartValue } from '@app/types';

export const getLocalCart = () => {
    // Get the cached local cart.
    let localCart: null | LocalCartValue = JSON.parse(
        window.localStorage.getItem(cartConfig.storageLocalCartKey)
    );

    // Local cart set?
    if (!localCart) {
        // No - create it.
        localCart = {
            lines: [],
            total: { amount: '$0.00', currencyCode: 'USD' }
        };
        window.localStorage.setItem(
            cartConfig.storageLocalCartKey,
            JSON.stringify(localCart)
        );
    }

    return localCart;
};

export const setLocalCart = (newLocalCart: LocalCartInput) =>
    window.localStorage.setItem(
        cartConfig.storageLocalCartKey,
        JSON.stringify(newLocalCart)
    );
