export enum ProductCategory {
    hydratehp = 'hydratehp',
    repairhp = 'repairhp',
    snoozehp = 'snoozehp',
    sparkhp = 'sparkhp'
}

export enum ProductSubcategory {
    appleDream = 'apple-dream',
    lemonBerryBoost = 'lemon-berry-boost',
    mangoDream = 'mango-dream',
    raspberryRecover = 'raspberry-recover',
    raspberryRevive = 'raspberry-revive',
    tropicalBerryBoost = 'tropical-berry-boost',
    tropicalRevive = 'tropical-revive',
    watermelonRecover = 'watermelon-recover'
}
