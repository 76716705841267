import { component, RefContext } from '@loom-js/core';

import { Input, InputProps } from '@app/components/simple/form-controls/input';

export interface AdaInputProps extends InputProps {
    inputClass?: string;
    inputRef?: RefContext;
    label?: string;
}

export const AdaInput = component<AdaInputProps>(
    (
        html,
        { className, inputClass, inputRef, label = '', name, ...inputProps }
    ) => html`
        <label class="${className}" for="${name}">
            ${Input({
                ...inputProps,
                className: inputClass,
                name,
                ref: inputRef
            })}
            <span>${label}</span>
        </label>
    `
);
