import { PRE_RENDER, USE_MOCKS } from '@app/constants';

// Email
export const contactNotifyMeUrl = '/api/contact/notify-me';
// Contentful
export const contentfulGraphQlUrl = USE_MOCKS
    ? '/mocks/contentful/page.json'
    : PRE_RENDER
    ? `https://graphql.contentful.com/content/v1/spaces/${process.env.CTF_SPACE_ID}/environments/master`
    : '/api/edge/contentful/graphql';
// Shopify
export const shopifyGraphQlUrl = USE_MOCKS
    ? '/mocks/shopfiy/products.json'
    : PRE_RENDER
    ? `https://${process.env.SHOPIFY_ID}/api/${process.env.SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`
    : '/api/edge/shopify/graphql';
