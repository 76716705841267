import { ContextFunction } from '@loom-js/core';
import classNames from 'classnames';

import styles from './styles.scss';

export interface ThemableProps {
    bgColor: string;
    color: string;
    theme: string;
}

export enum ThemeBorderStyle {
    Round = 'round',
    Sharp = 'sharp'
}

export enum ThemeColor {
    Brand = 'brand',
    Current = 'current',
    Neutral = 'neutral',
    Primary = 'primary',
    Root = 'root',
    Secondary = 'secondary'
}

export enum ThemePreset {
    Ghost = 'ghost',
    Hollow = 'hollow',
    Outline = 'outline',
    Solid = 'solid'
}

export interface ThemedProps {
    color?: ThemeColor;
    borderStyle?: ThemeBorderStyle;
    preset?: ThemePreset;
    themable: (props: ThemableProps) => ContextFunction;
}

export const Themed = ({
    borderStyle,
    color = ThemeColor.Root,
    preset,
    themable
}: ThemedProps) =>
    themable({
        bgColor: styles[`bg-${color}`],
        color: styles[color],
        theme: classNames(styles[borderStyle], styles[preset])
    });
