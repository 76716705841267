import { component, MouseEventListener, TemplateTagValue } from '@loom-js/core';
import classNames from 'classnames';

import styles from './styles.scss';

export interface ModalProps {
    className?: string;
    contents: TemplateTagValue;
    onOutsideClick?: MouseEventListener;
}

export const Modal = component<ModalProps>(
    (html, { className, contents, node, onOutsideClick }) => html`
        <div
            $click=${(e: Event) =>
                (node() as Node).isSameNode(e.target as Node) &&
                onOutsideClick &&
                onOutsideClick(e)}
            aria-modal="true"
            class=${classNames(styles.modal, className)}
            role="dialog"
        >
            <div class=${styles.modalContent}>${contents}</div>
        </div>
    `
);
