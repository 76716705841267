interface ValueRange {
    min?: number | string;
    max?: number | string;
}

/**
 * Ensures the value is in the `min`/`max` range.
 * @returns The value in range.
 */
export const valueInRange = (value: number = 0, { min, max }: ValueRange) => {
    const safeMin = Math.max(0, Number(min));
    const safeMax =
        max === undefined ? undefined : Math.max(safeMin, Number(max));
    const maxValue = Math.max(safeMin, value);

    return Math.min(safeMax ?? maxValue, maxValue);
};
