import { Component, MouseEventListener, TemplateTagValue } from '@loom-js/core';

import { Li, LiProps } from '@app/components/simple';

export interface ListItemsProps {
    listItem?: Component<object>;
    listProps: (LiProps | TemplateTagValue)[];
    onItemClick?: MouseEventListener;
}

export const ListItems = ({
    listItem,
    listProps,
    onItemClick
}: ListItemsProps) =>
    listProps.map((props) =>
        Li({
            children: listItem
                ? listItem(props as LiProps)
                : (props as TemplateTagValue),
            onClick: onItemClick
        })
    );
