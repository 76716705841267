import { component, MouseEventListener, TemplateTagValue } from '@loom-js/core';

import { CloseCta } from '@app/components/styled/buttons/close-cta';

export interface StandardModalContentsProps {
    body: TemplateTagValue;
    className?: string;
    footer?: TemplateTagValue;
    onCloseClick: MouseEventListener;
    title?: TemplateTagValue;
}

export const StandardModalContents = component<StandardModalContentsProps>(
    (html, { body, className, footer, onCloseClick, title }) => html`
        <div class=${className}>
            <header>
                ${title}
                ${CloseCta({
                    onClick: onCloseClick
                })}
            </header>
            ${body}
            <footer>${footer}</footer>
        </div>
    `
);
