import { component } from '@loom-js/core';

import { cartActivity } from '@app/activities/cart';
import { cartToggle } from '@app/activities/cart-toggle';
import { CartItemProps } from '@app/components/product/styled/cart-item';
import { ReactiveCartItems } from '@app/components/reactive/reactive-cart-items';
import { Paragraph, Span } from '@app/components/simple';
import { RectSolidButton } from '@app/components/styled/buttons/rect-solid-button';
import { ShoppingBagIcon } from '@app/components/styled/icons/shopping-bag-icon';
import { checkout } from '@app/helpers/shopify/checkout';

import styles from './styles.scss';

export type CartProps = CartItemProps;

export const Cart = component<CartProps>((html) => {
    const { effect: cartEffect } = cartActivity;
    const { update: toggleCart } = cartToggle;

    return html`
        <div class=${styles.cart}>
            <!-- Header content -->
            <header>
                <div class=${styles.totalItems}>
                    ${ShoppingBagIcon({})}
                    ${cartEffect(({ value: { lines } }) => {
                        return Span({
                            children: lines?.reduce(
                                (totalItems, { quantity }) =>
                                    (totalItems += quantity),
                                0
                            )
                        });
                    })}
                </div>
                <div>
                    <div>
                        <label>Subtotal</label>
                        ${cartEffect(({ value: { total } }) =>
                            Span({
                                children: `${total.amount}`,
                                className: styles.totalPrice
                            })
                        )}
                    </div>
                    ${cartEffect(({ value: { lines } }) => {
                        if (lines.length) {
                            return Paragraph({
                                children:
                                    'Tax will be calculated during Checkout',
                                className: styles.totalDisclaimer
                            });
                        }
                    })}
                </div>
            </header>
            <!-- Body content -->
            ${ReactiveCartItems(() => ({ className: styles.cartItems }))}
            <!-- Footer content -->
            <footer>
                ${cartEffect(({ value: { lines } }) =>
                    RectSolidButton({
                        children: Span({
                            children: lines.length
                                ? 'Checkout cart'
                                : 'Continue shopping'
                        }),
                        onClick: lines.length
                            ? checkout
                            : () => toggleCart(false)
                    })
                )}
            </footer>
        </div>
    `;
});
