import classNames from 'classnames';

import {
    ButtonProps,
    StandardButton
} from '@app/components/styled/buttons/standard-button';
import { RectSolid, ThemeColor } from '@app/components/styled/themed';

export type { ButtonProps };

export const RectSolidButton = ({ className, ...props }: ButtonProps) =>
    RectSolid({
        color: ThemeColor.Current,
        themable: ({ theme }) =>
            StandardButton({
                ...props,
                className: classNames(className, theme)
            })
    });
