import { InputValue } from '@app/types/elements/form';

/**
 * Sanitizes form data by trimming whitespace & encoding the values. Files are returned as is.
 * @param data The form data.
 * @returns The sanitized form data.
 */
export const sanitizeFormData = (data: FormData) =>
    Array.from<[string, InputValue]>((data as any).entries()).reduce(
        (acc, [name, value]) => {
            acc[name] = sanitizeInputValue(value);
            return acc;
        },
        {} as Record<string, ReturnType<typeof sanitizeInputValue>>
    );

/**
 * Sanitizes an input value by trimming whitespace & encoding the value. Files are returned as is.
 * @param value The input value.
 * @returns The sanitized input value.
 */
export const sanitizeInputValue = (value: InputValue) =>
    value instanceof File
        ? value
        : encodeURIComponent(typeof value === 'string' ? value.trim() : value);
