export interface ProductsQueryArgs {
    products: string;
    variants: string;
}

export interface ProductsQueryOptions {
    params?: ProductsQueryArgs;
}

const defaultArgs = 'first: 10';

export const productsQuery = (options: ProductsQueryOptions = {}) => {
    const {
        products: productsParam = defaultArgs,
        variants: variantsParam = defaultArgs
    } = options?.params || {};

    return `
        products(${productsParam}) {
            edges {
                node {
                    availableForSale
                    description
                    handle
                    id
                    options {
                        id
                        name
                        values
                    }
                    seo {
                        description
                        title
                    }
                    title
                    variants(${variantsParam}) {
                        edges {
                            node {
                                availableForSale
                                id
                                priceV2 {
                                    amount
                                    currencyCode
                                }
                                product {
                                    id
                                }
                                selectedOptions {
                                    name
                                    value
                                }
                                title
                            }
                        }
                    }
                }
            }
        }
    `;
};
