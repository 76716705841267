import { storefrontActivity } from '@app/activities/storefront';
import { getProducts } from '@app/helpers/api/shop/shopify';

// Fetch upfront app data before capturing prerender snapshot.
export const useProducts = async () => {
    const { update: updateStorefront } = storefrontActivity;
    // Pull the storefront data & update the activity.
    const { data: products } = await getProducts();

    updateStorefront({ products });
};
