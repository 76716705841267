import { component } from '@loom-js/core';
import classNames from 'classnames';

import {
    CartItem,
    CartItemProps
} from '@app/components/product/styled/cart-item';

import styles from './styles.scss';

export interface CartItemsProps {
    className?: string;
    items: CartItemProps[];
}

export const CartItems = component<CartItemsProps>(
    (html, { className, items = [] }) =>
        html`<div class=${classNames(styles.cartItems, className)}>
            ${items.map((item) => CartItem(item))}
        </div>`
);
