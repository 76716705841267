import { activity } from '@loom-js/core';

import { Product } from '@app/types/shopify';

export interface StorefrontActivityProps {
    products: Product[];
}

export const storefrontActivity = activity<StorefrontActivityProps>({
    products: []
});
