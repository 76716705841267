import { component } from '@loom-js/core';

import { SubscribeShortForm } from '@app/components/containers/content/forms/subscribe-short-form';
import { Link, LinkGroup } from '@app/components/simple';
import { UiSprite, UiSpriteId } from '@app/components/simple/ui-sprite';

import styles from './styles.scss';

export const Footer = component(
    (html) => html`
        <footer class=${styles.footer}>
            <div class=${styles.subscribe}>
                <h3>Stay Up To Date</h3>
                ${SubscribeShortForm()}
            </div>
            <div class=${styles.social}>
                <h3>Connect On Social</h3>
                ${LinkGroup({
                    className: styles.socialIcons,
                    linkProps: [
                        {
                            aria: { label: 'Hour Nutrition Facebook Page' },
                            children: UiSprite({
                                svgId: UiSpriteId.Facebook
                            }),
                            href: 'https://www.facebook.com/Hour-Nutrition-115238073936404'
                        },
                        {
                            aria: { label: 'Hour Nutrition Instagram Page' },
                            children: UiSprite({
                                svgId: UiSpriteId.Instagram
                            }),
                            href: 'https://www.instagram.com/hournutrition'
                        },
                        {
                            aria: { label: 'Hour Nutrition Twitter Page' },
                            children: UiSprite({
                                svgId: UiSpriteId.Twitter
                            }),
                            href: 'https://twitter.com/HourNutrition'
                        }
                    ]
                })}
            </div>
            <div class=${styles.contact}>
                <h3>Contact</h3>
                <p>
                    Hour Nutrition<br />
                    ${Link({
                        children: 'takeyourshot@hournutrition.com',
                        href: 'mailto:takeyourshot@hournutrition.com'
                    })}
                </p>
            </div>
        </footer>
    `
);
