import { ReactiveComponent } from '@loom-js/core';

import { cartActivity } from '@app/activities/cart';
import { storefrontActivity } from '@app/activities/storefront';
import { CartItemProps } from '@app/components/product/styled/cart-item';
import {
    CartItems,
    CartItemsProps
} from '@app/components/product/styled/cart-items';
import {
    getShopifyProductVariantById,
    ShopifyProductOptionName
} from '@app/helpers/shopify/utils';

export const ReactiveCartItems: ReactiveComponent<
    void,
    Pick<CartItemsProps, 'className'>
> = (transform) => {
    const { effect, update } = cartActivity;
    const onQuantityUpdate = (quantity: number, id: string) =>
        update({ line: { quantity, merchandiseId: id } });

    return effect(({ value: { lines } }) => {
        const { products } = storefrontActivity.value();
        const { className } = transform();

        if (!lines.length) {
            // Return a generic message.
            const $p = document.createElement('p');

            $p.style.textAlign = 'center';
            $p.classList.add(className);
            $p.appendChild(
                document.createTextNode('Looks like your cart is still empty.')
            );

            return $p;
        }

        // ...Or return the cart items.
        const items = products.reduce((acc, { handle, variants }) => {
            const itemsOfVariant = lines.reduce(
                (acc, { quantity, merchandiseId }) => {
                    const variant = getShopifyProductVariantById(
                        merchandiseId,
                        variants
                    );

                    // Process the variant if found.
                    if (quantity && variant) {
                        const { priceV2, selectedOptions } = variant;
                        const optionValues = selectedOptions.reduce(
                            (acc, { name, value }) => {
                                acc[name.toLowerCase()] = value;
                                return acc;
                            },
                            {}
                        );

                        // Define the cart item object.
                        acc.push({
                            category: handle,
                            id: merchandiseId,
                            quantity,
                            quantityMin: 0,
                            onQuantityUpdate,
                            price: `$${priceV2.amount}`,
                            productName:
                                optionValues[ShopifyProductOptionName.style],
                            size: optionValues[ShopifyProductOptionName.size],
                            subcategory: optionValues[
                                ShopifyProductOptionName.style
                            ]
                                .replace(/\s/g, '-')
                                .toLowerCase()
                        });
                    }

                    return acc;
                },
                [] as CartItemProps[]
            );
            return acc.concat(itemsOfVariant);
        }, [] as CartItemProps[]);

        return CartItems({
            className,
            items
        });
    });
};
