import {
    Button,
    ButtonProps,
    UiSprite,
    UiSpriteId
} from '@app/components/simple';

export const ArrowUpButton = (props: Omit<ButtonProps, 'children'>) =>
    Button({
        ...props,
        children: UiSprite({ svgId: UiSpriteId.ArrowUp })
    });
