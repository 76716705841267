import { ReactiveComponent } from '@loom-js/core';

import { modals, ModalsValue } from '@app/activities/modals';
import { Modal, ModalProps } from '@app/components/containers/content/modal';

export const ReactiveModal: ReactiveComponent<
    ModalsValue,
    ModalProps & { active: boolean }
> = (transform) =>
    modals.effect(({ value }) => {
        const { active, ...modalProps } = transform(value);

        if (active) {
            return Modal(modalProps);
        }
    });
