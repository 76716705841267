import { activity } from '@loom-js/core';

const observer = new ResizeObserver(() =>
    screenResize.update({ h: window.innerHeight, w: window.innerWidth })
);

export const screenResize = activity(
    {
        h: window.innerHeight,
        w: window.innerWidth
    },
    { deep: true }
);

observer.observe(window.document.body);
