import { ComponentOptionalProps } from '@loom-js/core';

import { Button, ButtonProps, ButtonType } from '@app/components/simple';

export type SubmitButtonProps = Omit<ButtonProps, 'type'> &
    ComponentOptionalProps;

export const SubmitButton = (props: SubmitButtonProps) =>
    Button({
        ...props,
        type: ButtonType.Submit
    });
