import { ReactiveComponent } from '@loom-js/core';

import { cartToggle } from '@app/activities/cart-toggle';
import { Cart } from '@app/components/containers/content/cart';
import { CartDrawer } from '@app/components/containers/content/drawers/cart-drawer';
import { CloseCta } from '@app/components/styled/buttons/close-cta';

export const ReactiveCart: ReactiveComponent<boolean> = (_transform) => {
    const { effect, update } = cartToggle;
    const hideCart = () => update(false);

    return effect(({ value }) =>
        CartDrawer({
            contents: Cart(),
            closeButton: CloseCta({
                onClick: hideCart
            }),
            isActive: value,
            onOutsideClick: hideCart
        })
    );
};
