import {
    lazyImport,
    onRouteUpdate,
    type ReactiveComponent,
    sanitizeLocation,
    TemplateTagValue
} from '@loom-js/core';

import { menuActiveState } from '@app/activities/menu-active-state';
import { type ProductFlavorProps } from '@app/components/containers/pages/product/product-flavor';
import { type ProductLandingProps } from '@app/components/containers/pages/product/product-landing';
import {
    ProductCategory,
    ProductSubcategory
} from '@app/components/containers/pages/product/types';
import { setHeadSeo } from '@app/helpers/seo/set-head-seo';
import { camelCase } from '@app/helpers/string/camel-case';

export enum AppRouteTransformType {
    product = 'product'
}

export interface ProductTransformProps {
    categoryName?: string;
    type?: AppRouteTransformType;
}

export interface ProductTransformReturn {
    className?: string;
}

const LazyAbout = () => import('@app/components/containers/pages/about');
const LazyHome = () => import('@app/components/containers/pages/home');
const LazyProductFlavor = () =>
    import('@app/components/containers/pages/product/product-flavor');
const LazyProductLanding = () =>
    import('@app/components/containers/pages/product/product-landing');

export const AppRoutes: ReactiveComponent<
    ProductTransformProps,
    ProductTransformReturn
> = (transform) => {
    const { effect: lazyRouteEffect, update: updateLazyRoute } =
        lazyImport<TemplateTagValue>('route', () => Promise.resolve(undefined));
    const { update: updateMenuActiveState } = menuActiveState;
    // Takes a RegExpMatchArray for a product URL pathname & parses each segment for the category
    // subcategory names. It returns the product page props.
    const getProductProps = (
        productUrlMatch: RegExpMatchArray
    ): Partial<{
        category: ProductCategory;
        className: string;
        subcategory: ProductSubcategory;
    }> => {
        const match = productUrlMatch || [];
        const fallbackCategory = ProductCategory.snoozehp;
        const categoryName = match[1] || fallbackCategory;
        const subcategoryName = match[2];
        const category = ProductCategory[categoryName];
        const subcategory =
            subcategoryName && ProductSubcategory[camelCase(subcategoryName)];
        const { className } = transform({
            categoryName,
            type: AppRouteTransformType.product
        });

        return {
            category,
            className,
            subcategory
        };
    };

    // Whenever the route updates & initially...
    onRouteUpdate(({ value: location }) => {
        const { pathname } = sanitizeLocation(location);
        let importer: (valueInput: TemplateTagValue) => void;
        // Matches to the path segment while omitting slashes.
        const pathSegmentRegex = '(?:\\/(\\w+[\\w|-]*))';
        const productFlavorRouteRegex = RegExp(
            `^\/product${pathSegmentRegex}${pathSegmentRegex}$`
        );
        const productLandingRouteRegex = RegExp(
            `^\/product${pathSegmentRegex}?$`
        );

        // Make sure we're at the top of the page on route changes.
        window.scrollTo(0, 0);

        switch (true) {
            // About
            case '/about' === pathname:
                importer = async () => {
                    const { About } = await LazyAbout();
                    return About();
                };
                break;
            // Home
            case '/' === pathname:
                importer = async () => {
                    const { Home } = await LazyHome();
                    return Home();
                };
                break;
            // ProductFlavor
            case productFlavorRouteRegex.test(pathname):
                // Find the product flavor category & subcategory names.
                const productFlavorProps = getProductProps(
                    pathname.match(productFlavorRouteRegex)
                );

                // Return the lazyloaded page or fallthrough to `default`...
                if (
                    productFlavorProps.category &&
                    productFlavorProps.subcategory
                ) {
                    importer = async () => {
                        const { ProductFlavor } = await LazyProductFlavor();
                        return ProductFlavor({
                            ...(productFlavorProps as ProductFlavorProps),
                            key: 'ProductFlavor'
                        });
                    };
                    break;
                }
            // ProductLanding
            case productLandingRouteRegex.test(pathname):
                // Find the product category name.
                const { subcategory: _, ...productLandingProps } =
                    getProductProps(pathname.match(productLandingRouteRegex));

                // Return the lazyloaded page or fallthrough to `default`...
                if (productLandingProps.category) {
                    importer = async () => {
                        const { ProductLanding } = await LazyProductLanding();
                        return ProductLanding({
                            ...(productLandingProps as ProductLandingProps),
                            key: 'ProductLanding'
                        });
                    };
                    break;
                }
            // Home
            // @TODO Should load up a 404 page.
            default:
                window.history.replaceState({}, 'redirect', '/');
                importer = async () => {
                    const { Home } = await LazyHome();
                    return Home();
                };
        }

        // ...ensure the menu closes.
        updateMenuActiveState(false);
        // ...and update the Head SEO tags.
        setHeadSeo();
        // ...then update the route
        updateLazyRoute(importer);
    });

    return lazyRouteEffect(({ value: lazyComponent }) => {
        return lazyComponent as TemplateTagValue;
    });
};
