import { component, onRoute } from '@loom-js/core';
import classNames from 'classnames';

import { menuActiveState } from '@app/activities/menu-active-state';
import { LinkGroup } from '@app/components/simple/link-group';

import styles from './styles.scss';

export interface SiteNavProps {
    className: string;
    menuIsActive?: boolean;
}

export const SiteNav = component<SiteNavProps>((html, { className }) => {
    // Menu activation activity
    const { effect: menuEffect } = menuActiveState;
    return html`
        <nav class=${classNames(styles.siteNav, className)}>
            ${menuEffect(({ value }) =>
                LinkGroup({
                    className: classNames(styles.menu, {
                        [styles.active]: value
                    }),
                    linkProps: [
                        {
                            children: 'Home',
                            href: '/'
                        },
                        {
                            children: 'Sleep',
                            href: '/product/snoozehp'
                        },
                        {
                            children: 'Endurance',
                            href: '/product/sparkhp'
                        },
                        {
                            children: 'Recovery',
                            href: '/product/repairhp'
                        },
                        {
                            children: 'Hydration',
                            href: '/product/hydratehp'
                        },
                        {
                            children: 'About',
                            href: '/about'
                        }
                    ],
                    onClick: onRoute
                })
            )}
        </nav>
    `;
});
