export interface CartQueryProps {
    args?: string;
    fragments?: string[];
    queries: string[];
    queryLabel?: string;
    queryType?: 'query' | 'mutation';
}

export const createQuery = ({
    args = '',
    fragments = [],
    queries = [],
    queryLabel = '',
    queryType = 'query'
}: CartQueryProps) => `
    ${queryType}${queryLabel && ` ${queryLabel}`}${args && `(${args})`} {
        ${queries.join('\n')}
    }
    ${fragments.join('\n')}
`;
