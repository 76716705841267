import classNames from 'classnames';

import {
    ControlledNumberInput,
    ControlledNumberInputProps
} from '@app/components/behavior/controlled-number-input';

import styles from './styles.scss';

export type QuantityControlProps = ControlledNumberInputProps;

export const QuantityControl = ({
    className,
    ...props
}: QuantityControlProps) =>
    ControlledNumberInput({
        ...props,
        className: classNames(styles.quantityControl, className)
    });
