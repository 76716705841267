import { createQuery } from '@app/helpers/graphql/query';
import {
    amountFields,
    attributeFields
} from '@app/helpers/graphql/shopify/fragments';
import {
    addCartLinesMutation,
    createCartMutation,
    removeCartLinesMutation,
    updateCartLinesMutation
} from '@app/helpers/graphql/shopify/mutations';
import { productsQuery } from '@app/helpers/graphql/shopify/queries';
import { shopifyRequest } from '@app/helpers/shopify/shopify-request';
import { LocalCartInput, ShopifyCart } from '@app/types';
import { ApiProviderResponse } from '@app/types/api';
import {
    CartConnection,
    CartLine,
    CartLineInput,
    CartLinesAddResponseData,
    CartLinesRemoveResponseData,
    CartLinesUpdateResponseData,
    CartResponseData,
    Product,
    ProductsResponseData,
    ShopifyGraphQlError
} from '@app/types/shopify';

export const addCartLines = async (id: string, lines: CartLineInput[]) => {
    let errorResponse = handleCartUpdateErrorsOnMissingArgs(id, lines);

    if (errorResponse) {
        return errorResponse;
    }

    return (await shopifyRequest<ShopifyCart, CartLinesAddResponseData>({
        adapter: ({ data, errors }) =>
            cartLinesAddAdapter({ data: data?.cartLinesAdd, errors }),
        query: createQuery({
            args: '$cartId: ID!, $lines: [CartLineInput!]!',
            fragments: [amountFields, attributeFields],
            queries: [addCartLinesMutation],
            queryLabel: 'addCartLines',
            queryType: 'mutation'
        }),
        variables: {
            cartId: id,
            lines: lines.map(({ merchandiseId, quantity }) => ({
                merchandiseId,
                quantity
            }))
        }
    })) as ApiProviderResponse<ShopifyCart>;
};

export const removeCartLines = async (id: string, lineIds: string[]) => {
    let errorResponse = handleCartUpdateErrorsOnMissingArgs(id, lineIds);

    if (errorResponse) {
        return errorResponse;
    }

    return (await shopifyRequest<ShopifyCart, CartLinesRemoveResponseData>({
        adapter: ({ data, errors }) =>
            errors ? handleErrorsOn200(errors, {}) : data.cartLinesRemove.cart,
        query: createQuery({
            args: '$cartId: ID!, $lineIds: [ID!]!',
            fragments: [amountFields, attributeFields],
            queries: [removeCartLinesMutation],
            queryLabel: 'removeCartLines',
            queryType: 'mutation'
        }),
        variables: {
            cartId: id,
            lineIds
        }
    })) as ApiProviderResponse<ShopifyCart>;
};

export const createCart = async ({ lines }: Pick<LocalCartInput, 'lines'>) =>
    (await shopifyRequest<ShopifyCart, CartResponseData>({
        adapter: ({ data, errors }) =>
            cartLinesAddAdapter({ data: data?.cartCreate, errors }),
        query: createQuery({
            args: '$cartInput: CartInput',
            fragments: [amountFields, attributeFields],
            queries: [createCartMutation()],
            queryLabel: 'createCart',
            queryType: 'mutation'
        }),
        variables: {
            cartInput: {
                lines: lines.map(({ merchandiseId, quantity }) => ({
                    merchandiseId,
                    quantity
                }))
            }
        }
    })) as ApiProviderResponse<ShopifyCart>;

export const getProducts = async () =>
    (await shopifyRequest<Product[], ProductsResponseData>({
        adapter: ({ data, errors }) => {
            if (errors) {
                return handleErrorsOn200(errors, []);
            }

            return data.products.edges.reduce<Product[]>(
                (acc, { node }) =>
                    node.availableForSale ? acc.concat(node) : acc,
                []
            );
        },
        query: createQuery({
            queries: [productsQuery()]
        })
    })) as ApiProviderResponse<Product[]>;

export const updateCartLines = async (id: string, lines: CartLineInput[]) => {
    let errorResponse = handleCartUpdateErrorsOnMissingArgs(id, lines);

    if (errorResponse) {
        return errorResponse;
    }

    return (await shopifyRequest<ShopifyCart, CartLinesUpdateResponseData>({
        adapter: ({ data, errors }) =>
            errors ? handleErrorsOn200(errors, {}) : data.cartLinesUpdate.cart,
        query: createQuery({
            args: '$cartId: ID!, $lines: [CartLineUpdateInput!]!',
            fragments: [amountFields, attributeFields],
            queries: [updateCartLinesMutation],
            queryLabel: 'updateCartLines',
            queryType: 'mutation'
        }),
        variables: {
            cartId: id,
            lines: lines.map(({ id, merchandiseId, quantity }) => ({
                id,
                merchandiseId,
                quantity
            }))
        }
    })) as ApiProviderResponse<ShopifyCart>;
};

const cartLinesAddAdapter = ({
    data,
    errors
}: {
    data: CartConnection;
    errors: ShopifyGraphQlError[];
}) => {
    if (errors) {
        return handleErrorsOn200(errors, {});
    }

    const { cart } = data;
    const { lines } = cart;

    (cart as unknown as ShopifyCart).lines = lines.edges.reduce<CartLine[]>(
        (acc, { node }) =>
            node?.merchandise.availableForSale ? acc.concat(node) : acc,
        []
    );

    return cart;
};

const handleErrorsOn200 = (errors: ShopifyGraphQlError[], safeValue?: any) => {
    console.error('ShopifyGraphQlError(s)', errors);
    return safeValue;
};

const handleCartUpdateErrorsOnMissingArgs = (
    id: string,
    lines: (CartLineInput | string)[]
): ApiProviderResponse<ShopifyCart> | void => {
    let error: false | string = false;

    if (!id) {
        // Missing `id`
        error = `A cart ID was not provided, but required for the request - received ${id}`;
    }

    if (!lines?.length) {
        // Missing `lines` or one or more lines not provided.
        error = `Missing \`lines\` or one or more cart lines were not provided, but required for the request - received ${JSON.stringify(
            lines
        )}`;
    }

    if (error) {
        // Return a proper error response - "Bad Request".
        return {
            error: {
                code: 400,
                message: `API Request [Update Shopify Cart Lines]: ${error}`
            },
            success: false
        } as ApiProviderResponse<ShopifyCart>;
    }
};
