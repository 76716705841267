import { component, ComponentOptionalProps } from '@loom-js/core';

export type LiProps = Pick<
    ComponentOptionalProps,
    'children' | 'className' | 'onClick'
>;

export const Li = component<LiProps>(
    (html, { children, className, onClick }) =>
        html`<li $click=${onClick} class=${className}>${children}</li>`
);
