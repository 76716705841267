import { TemplateTagValue } from '@loom-js/core';
import classNames from 'classnames';

import { modals } from '@app/activities/modals';
import { ReactiveModal } from '@app/components/reactive/reactive-modal';

import {
    StandardModalContents,
    StandardModalContentsProps
} from './standard-modal-contents';
import styles from './styles.scss';

export type StandardModalProps<B, F> = Omit<
    StandardModalContentsProps,
    'body' | 'footer' | 'onCloseClick'
> & {
    body: (props: B) => TemplateTagValue;
    footer?: (props: F) => TemplateTagValue;
    id: string;
};

export const StandardModal = <B, F>({
    body,
    className,
    footer,
    id,
    ...props
}: StandardModalProps<B, F>) => {
    const { update: updateModals } = modals;
    const onCloseClick = () => id && updateModals({ [id]: undefined });

    return ReactiveModal((modals) => {
        const modalContentsProps = (modals[id] || {}) as B & F;

        // `ModalProps`
        return {
            active: modals[id] !== undefined,
            className: classNames(styles.standardModal, className),
            contents: StandardModalContents({
                ...props,
                body: body(modalContentsProps),
                className: styles.standardModalContents,
                footer: footer(modalContentsProps),
                onCloseClick
            }),
            onOutsideClick: onCloseClick
        };
    });
};
