import type {
    ActivityEffectAction,
    ContextFunction,
    SimpleComponent
} from '@loom-js/core';
import classNames from 'classnames';

import { cartActivity } from '@app/activities/cart';
import { cartToggle } from '@app/activities/cart-toggle';
import { modals } from '@app/activities/modals';
import { Span } from '@app/components/simple';
import { RectSolidButton } from '@app/components/styled/buttons/rect-solid-button';
import { ProductVariant } from '@app/types/shopify';

import styles from './styles.scss';

export interface AddToCartFooterProps extends ProductVariant {
    className: string;
    itemQuantityEffect: (
        action: ActivityEffectAction<number>
    ) => ContextFunction;
    modalId: string;
}

export const AddToCartFooter: SimpleComponent<AddToCartFooterProps> = ({
    className,
    id,
    itemQuantityEffect,
    modalId,
    priceV2
}) => {
    const { update: updateCart, value: localCart } = cartActivity;
    const { update: toggleCart } = cartToggle;
    const { update: updateModals } = modals;
    const closeModal = () => modalId && updateModals({ [modalId]: undefined });
    // @TODO Dependent on allowable product - SnoozeHp. Remove when all products are purchasable.
    const isSnooze = window.location.pathname.toLowerCase().includes('snooze');

    return [
        RectSolidButton({
            children: Span({ children: 'Cancel' }),
            className: styles.cancelCta,
            onClick: closeModal
        }),
        itemQuantityEffect(({ value: quantity }) => {
            return RectSolidButton({
                children: Span({
                    children: isSnooze ? 'Add to cart' : 'Coming Soon'
                }),
                className: classNames(styles.acceptCta, className),
                // @TODO Dependent on allowable product - SnoozeHp. Remove when all products are purchasable.
                onClick: () => {
                    // @TODO Dependent on allowable product - SnoozeHp. Remove when all products are purchasable.
                    if (!isSnooze) {
                        closeModal();
                        return;
                    }

                    const { lines } = localCart();
                    const existingCartItem = lines.find(
                        ({ merchandiseId }) => merchandiseId === id
                    );
                    // If the cart item exists in the local cart, add the new quantity to the existing one.
                    const currentQuantity = existingCartItem?.quantity || 0;

                    updateCart({
                        line: {
                            quantity: currentQuantity + quantity,
                            merchandiseId: id,
                            total: priceV2
                        }
                    });
                    modalId && updateModals({ [modalId]: undefined });
                    toggleCart(true);
                }
            });
        })
    ];
};
