const imageUrlBase = 'https://images.ctfassets.net';

export interface MediaOptions {
    assetId: string;
    filename: string;
    fit?: 'crop' | 'fill' | 'pad' | 'scale' | 'thumb';
    format: 'avif' | 'gif' | 'jpg' | 'png' | 'webp';
    height?: number;
    uid: string;
    width?: number;
}

export interface MediaUrls {
    [key: string]: (options: Partial<MediaOptions>) => string;
}

const param = (key: string, value: number | string) =>
    value ? `&${key}=${value}` : '';

export const getImageUrl = ({
    assetId,
    filename,
    fit,
    format,
    height,
    uid,
    width
}: Partial<MediaOptions>) =>
    `${imageUrlBase}/${
        process.env.CTF_SPACE_ID
    }/${assetId}/${uid}/${filename}?fm=${format}${param('fit', fit)}${param(
        'h',
        height
    )}${param('w', width)}`;

export const mediaUrls: MediaUrls = {
    'about-emily': (options) =>
        getImageUrl({
            ...options,
            assetId: '29EM5JRWwz9WBb5ltKZCa',
            filename: 'about-emily.jpg',
            uid: 'f0c7871895d731d52feb55943884441d'
        }),
    'about-ricky': (options) =>
        getImageUrl({
            ...options,
            assetId: '3zht4i4rWLD63sZpZsXdsQ',
            filename: 'about-ricky.jpg',
            uid: '0ffb99356fe6f82c06737b064b8a1721'
        }),
    'apple-dream': (options) =>
        getImageUrl({
            ...options,
            assetId: 'zDjB3mxPC8AZpTIHC3OrT',
            filename: 'apple-dream.png',
            uid: '2421aca6441f54155e865f33d59e7e29'
        }),
    'lemon-berry-boost': (options) =>
        getImageUrl({
            ...options,
            assetId: '5VOc21dwVDckD4PkyL79uo',
            filename: 'lemon-berry-boost.png',
            uid: '4fe906d62b4ab2655f4e795d9016ffe9'
        }),
    'hour-nutrition-logo': (options) =>
        getImageUrl({
            ...options,
            assetId: '5tj0gQJmX9jmwohOMTc7c5',
            filename: 'hour-nutrition-logo.png',
            uid: 'f5b8cbc2b272d4eaf29a7925cc9342c7'
        }),
    hydratehp: (options) =>
        getImageUrl({
            ...options,
            assetId: 'cg7d0TEPnPBeJmOaUGSX9',
            filename: 'hydratehp.jpg',
            uid: '4866d3f11024ed209bddd7eb70fd2017'
        }),
    'mango-dream': (options) =>
        getImageUrl({
            ...options,
            assetId: 'bBIdmmtcjLlKqllEW07Zw',
            filename: 'mango-dream.png',
            uid: '4ab60e21cf1b60709c776b793e1bcd91'
        }),
    'raspberry-revive': (options) =>
        getImageUrl({
            ...options,
            assetId: '3saTFuZd0fdSp22q75yWdn',
            filename: 'raspberry-revive.png',
            uid: '0faa9ccb5e98428f30708924fdc3c43b'
        }),
    'raspberry-recover': (options) =>
        getImageUrl({
            ...options,
            assetId: '1iV1PeybKMKLqC1xauf1Ki',
            filename: 'raspberry-recover.png',
            uid: '5e641044a69bb38ec0aca3071952f3f7'
        }),
    repairhp: (options) =>
        getImageUrl({
            ...options,
            assetId: '4hqqhjkrS76wpu4fNcABQg',
            filename: 'repairhp.jpg',
            uid: 'b5d5eba816b8af165d074349f332d96d'
        }),
    snoozehp: (options) =>
        getImageUrl({
            ...options,
            assetId: '6W4vGBq7oKAFxGrs5azwqh',
            filename: 'snoozehp.jpg',
            uid: 'f15098727d7b39f3f0ef406b670d3eb2'
        }),
    sparkhp: (options) =>
        getImageUrl({
            ...options,
            assetId: 'Wxw4ddCYZNCojzcIC1ctN',
            filename: 'sparkhp.jpg',
            uid: '6d0aee4f226e3d7e967066443bcdc282'
        }),
    'tropical-berry-boost': (options) =>
        getImageUrl({
            ...options,
            assetId: '7kb3AkgiwKtz65qoaIiksY',
            filename: 'tropical-berry-boost.png',
            uid: 'cd527865d9f658f328f2a6eaccf26e2c'
        }),
    'tropical-revive': (options) =>
        getImageUrl({
            ...options,
            assetId: '5ILqH7xdx00AHtNn0IGYvN',
            filename: 'tropical-revive.png',
            uid: 'a04bb04caf7505dc53916b1f165c7a5a'
        }),
    'video-poster-home': (options) =>
        getImageUrl({
            ...options,
            assetId: '24yKEDmzhtwoOdW5FdqvvD',
            filename: 'video-poster-home.jpg',
            uid: '61779a498b039e240ab453d8e07d69cc'
        }),
    'watermelon-recover': (options) =>
        getImageUrl({
            ...options,
            assetId: '2k4DJtLFo9A6F2NSDUgGF7',
            filename: 'watermelon-recover.png',
            uid: '67f5153beb62390a8639c1019a3cd23d'
        })
};
