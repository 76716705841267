import { activity, component } from '@loom-js/core';

import {
    NumberInput,
    NumberInputProps
} from '@app/components/behavior/number-input';
import { MinusCta } from '@app/components/styled/buttons/minus-cta';
import { PlusCta } from '@app/components/styled/buttons/plus-cta';
import { valueInRange } from '@app/helpers/number/value-in-range';

export interface ControlledNumberInputProps extends NumberInputProps {
    onValueUpdate?: (quantity: number) => void;
}

export const ControlledNumberInput = component<ControlledNumberInputProps>(
    (
        html,
        {
            className,
            createRef,
            min,
            max,
            name,
            onValueUpdate,
            value = 1,
            ...numberInputProps
        }
    ) => {
        const ref = createRef();
        const { effect, update } = activity(value);
        const bubbleValueUpdate = (newVal: number) =>
            onValueUpdate && onValueUpdate(valueInRange(newVal, { min, max }));
        // Activity updater - decrement/increment the value.
        const onControlClick = (offset: number) => {
            const node = ref.node() as HTMLInputElement;
            const newVal = Number(node.value) + offset;

            update(newVal);
            bubbleValueUpdate(newVal);
        };

        return html`
            <div class=${className}>
                ${MinusCta({
                    onClick: onControlClick.bind(null, -1)
                })}
                ${effect(({ value }) =>
                    NumberInput({
                        ...numberInputProps,
                        inputRef: ref,
                        min,
                        max,
                        name,
                        onInput: (e) =>
                            bubbleValueUpdate(
                                Number(
                                    (e.target as unknown as HTMLInputElement)
                                        .value
                                )
                            ),
                        value
                    })
                )}
                ${PlusCta({
                    onClick: onControlClick.bind(null, 1)
                })}
            </div>
        `;
    }
);
