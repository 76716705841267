import { UiSprite, UiSpriteId } from '@app/components/simple/ui-sprite';
import { ButtonProps, IconCta } from '@app/components/styled/buttons/icon-cta';

export const PlusCta = (props: Omit<ButtonProps, 'children'>) =>
    IconCta({
        ...props,
        children: UiSprite({
            svgId: UiSpriteId.Plus
        })
    });
