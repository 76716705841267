// Should be intialized & then called for every internal navigation.
export const setHeadSeo = async () => {
    // Do stuff when mounted onto the DOM...
    const titlePrefix = 'Hour Nutrition';
    const pageSeoMap = {
        '/': {
            desc: 'Hour Nutrition',
            title: 'Home'
        },
        '/about': {
            desc: 'About Hour Nutrition and the team.',
            title: 'About'
        },
        '/product/hydratehp': {
            desc: 'Hydration Enhancer - Hydrate provides a highly concentrated blend of electrolytes including Calcium, Chloride, Magnesium, Potassium, and Sodium to hydrate your body immediately when you need it with exactly the right combination of ingredients to aid your performance.',
            title: 'Hydrate HP'
        },
        '/product/hydratehp/raspberry-revive': {
            desc: 'Hydration Enhancer - Raspberry Revive',
            title: 'Hydrate HP - Raspberry Revive'
        },
        '/product/hydratehp/tropical-revive': {
            desc: 'Hydration Enhancer - Tropical Revive',
            title: 'Hydrate HP - Tropical Revive'
        },
        '/product/repairhp': {
            desc: "Recovery Enhancer - Provides a proprietary blend of essential amino acids and protein combined with anti-inflammatory powerhouses turmeric, bromelain, serrapeptase, green tea polyphenols, and grape extract; all to power your body's recovery so you can bounce back faster.",
            title: 'Repair HP'
        },
        '/product/repairhp/raspberry-recover': {
            desc: 'Recovery Enhancer - Raspberry Recover',
            title: 'Repair HP - Raspberry Recover'
        },
        '/product/repairhp/watermelon-recover': {
            desc: 'Recovery Enhancer - Watermelon Recover',
            title: 'Repair HP - Watermelon Recover'
        },
        '/product/snoozehp': {
            desc: 'Sleep Enhancer - Snooze provides a precise proprietary blend of Melatonin, Valerian Root, GABA, L-Theanine and 5-HTP to help you fall and stay asleep, combined with Magnesium, B6 and Zinc to accelerate your body’s recovery from workouts and other daily stresses.',
            title: 'Snooze HP'
        },
        '/product/snoozehp/apple-dream': {
            desc: 'Sleep Enhancer - Apple Dream',
            title: 'Snooze HP - Apple Dream'
        },
        '/product/snoozehp/mango-dream': {
            desc: 'Sleep Enhancer - Mango Dream',
            title: 'Snooze HP - Mango Dream'
        },
        '/product/sparkhp': {
            desc: 'Endurance Enhancer - Spark provides a proprietary blend of electrolytes, BCAAs, Caffeine, Taurine and B12 to give your body that boost it needs before working out to enhance performance and provide lasting energy.',
            title: 'Spark HP'
        },
        '/product/sparkhp/lemon-berry-boost': {
            desc: 'Endurance Enhancer - Lemon Berry Boost',
            title: 'Spark HP - Lemon Berry Boost'
        },
        '/product/sparkhp/tropical-berry-boost': {
            desc: 'Endurance Enhancer - Tropical Berry Boost',
            title: 'Spark HP - Tropical Berry Boost'
        }
    };

    // Sets the canonical link for the current page based on the url pathname.
    const setCanonical = () => {
        const canonicalDomain = 'https://hournutrition.com';
        let $link: HTMLLinkElement;
        const $canonicalLink: HTMLLinkElement = document.head.querySelector(
            'link[rel="canonical"]'
        );

        if ($canonicalLink) {
            $link = $canonicalLink;
            $link.href = `${canonicalDomain}${window.location.pathname}`;
        } else {
            $link = document.createElement('link');
            $link.href = `${canonicalDomain}${window.location.pathname}`;
            $link.rel = 'canonical';
            document.head.appendChild($link);
        }
    };
    // Sets the document's meta description for the current page based on the url pathname.
    const setMetaDescription = () => {
        let $meta: HTMLMetaElement;
        const $metaDescription: HTMLMetaElement = document.head.querySelector(
            'meta[name="description"]'
        );

        if ($metaDescription) {
            $meta = $metaDescription;
            $meta.content = pageSeoMap[window.location.pathname].desc;
        } else {
            $meta = document.createElement('meta');
            $meta.content = pageSeoMap[window.location.pathname].desc;
            $meta.name = 'description';
            document.head.appendChild($meta);
        }
    };
    // Sets the document's title for the current page based on the url pathname.
    const setTitle = () => {
        let $title: HTMLTitleElement;
        const $pageTitle: HTMLTitleElement =
            document.head.querySelector('title');

        if ($pageTitle) {
            $title = $pageTitle;
            $title.textContent = `${titlePrefix} | ${
                pageSeoMap[window.location.pathname].title
            }`;
        } else {
            $title = document.createElement('title');
            $title.textContent = `${titlePrefix} | ${
                pageSeoMap[window.location.pathname].title
            }`;
            document.head.appendChild($title);
        }
    };

    setCanonical();
    setMetaDescription();
    setTitle();

    return await Promise.resolve(true);
};
