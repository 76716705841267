export const cartQuery = `
cart {
    id
    checkoutUrl
    createdAt
    updatedAt
    lines(first:10) {
        edges {
            node {
                id
                quantity
                merchandise {
                    ... on ProductVariant {
                        id
                        availableForSale
                    }
                }
            }
        }
    }
    attributes {
        ...attributeFields
    }
    estimatedCost {
        totalAmount {
            ...amountFields
        }
        subtotalAmount {
            ...amountFields
        }
        totalTaxAmount {
            ...amountFields
        }
        totalDutyAmount {
            ...amountFields
        }
    }
}
`;
