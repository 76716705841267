import { component } from '@loom-js/core';
import classNames from 'classnames';

import {
    BrandingSprite,
    BrandingSpriteId
} from '@app/components/simple/branding-sprite';
import { ContentfulImage } from '@app/components/simple/media/contentful-image';
import { QuantityControl } from '@app/components/styled/form-controls/quantity-control';
import { mediaUrls } from '@app/helpers/contentful/media-urls';

import styles from './styles.scss';

export interface CartItemProps {
    category: string;
    className?: string;
    id: string;
    quantity: number;
    quantityMin?: number;
    onQuantityUpdate?: (quantity: number, id: string) => void;
    price: string;
    productName?: string;
    size: string;
    subcategory: string;
}

export const CartItem = component<CartItemProps>(
    (
        html,
        {
            category,
            className,
            id,
            quantity,
            quantityMin,
            onQuantityUpdate,
            price,
            productName,
            size,
            subcategory
        }
    ) => html`
        <div class=${classNames(styles.cartItem, className)}>
            ${ContentfulImage({
                description: productName,
                height: 80,
                sources: [
                    {
                        srcSet: mediaUrls[subcategory]({
                            format: 'webp',
                            width: 205
                        }),
                        type: 'image/webp'
                    }
                ],
                url: mediaUrls[subcategory]({
                    format: 'png',
                    width: 205
                }),
                width: 205
            })}
            <div class=${styles.cartItemDetails}>
                <hgroup>
                    <h1>
                        ${BrandingSprite({
                            height: '8em',
                            svgId: BrandingSpriteId[category],
                            width: '13em'
                        })}
                    </h1>
                    <h2>${productName}</h2>
                    <h3>${size}</h3>
                    <h3>${price}/<small>count</small></h3>
                </hgroup>
                ${QuantityControl({
                    min: quantityMin,
                    name: `quantityControl-${id}`,
                    onValueUpdate: (quantity) =>
                        onQuantityUpdate && onQuantityUpdate(quantity, id),
                    value: quantity
                })}
            </div>
        </div>
    `
);
