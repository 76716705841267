import { Svg, SvgProps } from '@app/components/simple';

export enum BrandingSpriteId {
    hydratehp = 'hydratehp',
    repairhp = 'repairhp',
    snoozehp = 'snoozehp',
    sparkhp = 'sparkhp'
}

export interface BrandingSpriteProps extends SvgProps {
    svgId: BrandingSpriteId;
}

export const BrandingSprite = (props: BrandingSpriteProps) =>
    Svg({
        ...props,
        path: '/assets/svg/branding-sprite.svg'
    });
