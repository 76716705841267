import classNames from 'classnames';

import { IconProps, UiSprite } from '@app/components/simple/ui-sprite';

import styles from './styles.scss';

export type { IconProps };

export const StandardIcon = ({ className, ...props }: IconProps) =>
    UiSprite({
        ...props,
        className: classNames(className, styles.standardIcon)
    });
