import { init } from '@loom-js/core';

import { App } from '@app/components/app';

init({
    app: App(),
    globalConfig: {
        debug: false
    },
    root: document.getElementById('app-root') || undefined
});
