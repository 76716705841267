import { Aria, ComponentOptionalProps } from '@loom-js/core';

import { Link, LinkProps, LiProps, Ul } from '@app/components/simple';

export interface LinkGroupProps extends ComponentOptionalProps {
    linkProps?: (LinkProps & LiProps & { aria?: Aria })[];
}

export const LinkGroup = ({ className, linkProps, onClick }: LinkGroupProps) =>
    Ul({
        className,
        listItem: (props: LinkProps) => Link({ ...props, onClick }),
        listProps: linkProps
    });
