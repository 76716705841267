import classNames from 'classnames';

import {
    RoundHollowedButton,
    ButtonProps
} from '@app/components/styled/buttons/round-hollowed-button';

import styles from './styles.scss';

export type { ButtonProps };

export const IconCta = ({ className, ...props }: ButtonProps) =>
    RoundHollowedButton({
        className: classNames(styles.iconCta, className),
        ...props
    });
