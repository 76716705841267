import { cartActivity } from '@app/activities/cart';
import { cartToggle } from '@app/activities/cart-toggle';
import { createCart, updateCartLines } from '@app/helpers/api/shop/shopify';
import { LocalCartInput, ShopifyCart } from '@app/types';
import { ApiProviderResponse } from '@app/types/api';

const { update: updateCart, value: localCart } = cartActivity;
const { update: toggleCart } = cartToggle;

export const checkout = async () => {
    // Do checkout steps...
    let cartResponse: ApiProviderResponse<ShopifyCart>;
    let cartUpdate: LocalCartInput;
    const localCartValue = localCart();

    // Persist the local cart to the cloud.
    if (localCartValue.id) {
        // Update the remote cart w/ local cart updated lines.
        cartResponse = await updateCartLines(
            localCartValue.id,
            localCartValue.lines
        );
        cartUpdate = { lines: localCartValue.lines };
    } else {
        // Create the remote cart w/ local cart (cart input.)
        cartResponse = await createCart({ lines: localCartValue.lines });

        if (cartResponse.error) {
            // Unsuccessful
            console.error(
                'Failed to create the cart with Shopify.',
                cartResponse.error
            );
        } else if (cartResponse.data.id) {
            // Cart created successfully - update the lines w/ their ID's.
            const { checkoutUrl, id, lines } = cartResponse.data;
            const localCartLines = lines.map(({ id: lineId, merchandise }) => {
                const line = localCartValue.lines.find(
                    ({ merchandiseId }) => merchandiseId === merchandise.id
                );

                line.id = lineId;
                return line;
            });

            cartUpdate = { checkoutUrl, id, lines: localCartLines };
        }
    }

    // Update the local cart or warn that the remote cart couldn't be updated.
    if (cartUpdate) {
        await updateCart(cartUpdate);
        toggleCart(false);
        // Follow to checkout URL.
        window.open(localCart().checkoutUrl, '_blank');
    } else {
        console.warn(
            'Checkout could not be done at this time. Try again later.'
        );
    }
};
