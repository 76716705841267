import { PRE_RENDER, USE_MOCKS } from '@app/constants';
import { mockRequest } from '@app/helpers/api/mock-request';
import { shopifyGraphQlUrl } from '@app/helpers/api/urls';
import {
    requestHeaders as headers,
    request,
    RequestOptions
} from '@app/helpers/http/request';
import { GraphQlRequestPayload } from '@app/types';
import { ShopifyGraphQlResponse } from '@app/types/shopify';

export const shopifyRequest = <D, T = D>({
    query,
    variables = {},
    ...requestConfig
}: RequestInit &
    RequestOptions<D, ShopifyGraphQlResponse<T>> &
    GraphQlRequestPayload) => {
    PRE_RENDER &&
        headers.append(
            'X-Shopify-Storefront-Access-Token',
            process.env.SHOPIFY_ACCESS_TOKEN
        );

    return USE_MOCKS
        ? mockRequest<D, T>(shopifyGraphQlUrl)
        : request<D, ShopifyGraphQlResponse<T>>(shopifyGraphQlUrl, {
              ...requestConfig,
              body: JSON.stringify({
                  query,
                  variables
              }),
              headers,
              method: 'POST'
          });
};
