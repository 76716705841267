import { activity, component } from '@loom-js/core';
import classNames from 'classnames';

import {
    AdaInput,
    Input,
    InputType,
    SubmitButton
} from '@app/components/simple/form-controls';
import { UiSprite, UiSpriteId } from '@app/components/simple/ui-sprite';
import { Loader } from '@app/components/styled/loader';
import { notifyMe } from '@app/helpers/api/contact/notify-me';

import styles from './styles.scss';

export interface SubscribeShortFormProps {
    className?: string;
}

const required = true;

export const SubscribeShortForm = component<SubscribeShortFormProps>(
    (html, { className }) => {
        const waiting = activity(false);
        const onSubmit: EventListener = async function (e) {
            e.preventDefault();
            waiting.update(true);
            const { success } = await notifyMe(this);
            waiting.update(false);

            if (success) {
                this.reset();
            }
        };

        return html`
            <form
                class=${classNames(styles.form, className)}
                $submit=${onSubmit}
            >
                ${AdaInput({
                    className: styles.control,
                    label: 'Your Email Address',
                    name: 'email',
                    placeholder: 'Type in your email',
                    required,
                    type: InputType.Email
                })}
                ${Input({
                    name: 'name',
                    type: InputType.Hidden,
                    value: 'Footer Subscription'
                })}
                ${waiting.effect(({ value: loading }) =>
                    SubmitButton({
                        aria: { label: 'Submit' },
                        children: loading
                            ? Loader()
                            : UiSprite({
                                  svgId: UiSpriteId.ArrowRight
                              }),
                        disabled: loading
                    })
                )}
            </form>
        `;
    }
);
