import { UiSprite, UiSpriteId } from '@app/components/simple/ui-sprite';
import { ButtonProps, IconCta } from '@app/components/styled/buttons/icon-cta';

export interface MenuCtaProps extends Omit<ButtonProps, 'children'> {
    active: boolean;
}

export const MenuCta = ({ active, ...props }: MenuCtaProps) =>
    IconCta({
        ...props,
        children: UiSprite({
            svgId: active ? UiSpriteId.Minus : UiSpriteId.Plus
        })
    });
