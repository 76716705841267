import { activity, ActivityTransform } from '@loom-js/core';

import { addCartLines, removeCartLines } from '@app/helpers/api/shop/shopify';
import { getLocalCart, setLocalCart } from '@app/helpers/cache/local-cart';
import { LocalCartInput, LocalCartValue } from '@app/types';

const cartUpdateTransform: ActivityTransform<
    LocalCartValue,
    LocalCartInput
> = async ({ input: { id, checkoutUrl, line, lines }, update, value }) => {
    let lineAdded = false;
    let lineRemoved = false;

    if (line?.merchandiseId) {
        // Handle line updates.
        const existingLineItemIndex = value.lines.findIndex(
            ({ merchandiseId }) => merchandiseId === line.merchandiseId
        );
        const existingLineItem = value.lines[existingLineItemIndex];

        if (existingLineItem) {
            // This is a quanity update.
            if (line.quantity <= 0) {
                // Remove the line item.
                value.lines.splice(existingLineItemIndex, 1);
                lineRemoved = true;
            } else {
                // Update line item quantity.
                existingLineItem.quantity = line.quantity;
            }
        } else {
            // Add the line item to the local cart.
            value.lines.push(line);
            lineAdded = true;
        }
    } else if (lines) {
        // Handle lines override.
        value.lines = lines;
    }

    // Update the cart ID.
    if (id) {
        value.id = id;
    } else if (id === '') {
        delete value.id;
    }

    // Update the checkout URL.
    value.checkoutUrl = checkoutUrl ?? value.checkoutUrl;

    if (value.id && lineAdded) {
        const { data, error } = await addCartLines(value.id, [line]);

        // Handle line updates - add line ID to locally cached line entries.
        if (error) {
            // Unsuccessful
            console.error('Failed to add the cart lines.', error);
        } else if (data.lines) {
            // Cart lines added successfully - update the lines w/ their ID's.
            data.lines.forEach(({ id, merchandise }) => {
                const existingLineItem = value.lines.find(
                    ({ merchandiseId }) => merchandiseId === merchandise.id
                );
                existingLineItem.id = id;
            });
        }
    } else if (value.id && lineRemoved) {
        await removeCartLines(value.id, [line.id]);
    }

    // Update the cart total.
    value.total.amount = (
        value.lines.length
            ? value.lines.reduce(
                  (acc, { quantity, total }) =>
                      (acc += Number(total.amount) * quantity),
                  0
              )
            : 0
    ).toLocaleString('en-US', {
        currency: 'USD',
        style: 'currency'
    });

    // Cache the local cart.
    setLocalCart(value);
    update(value);
};

export const cartActivity = activity<LocalCartValue, LocalCartInput>(
    getLocalCart(),
    cartUpdateTransform,
    { deep: true, force: true }
);
