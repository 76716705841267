import { contactNotifyMeUrl } from '@app/helpers/api/urls';
import { sanitizeFormData } from '@app/helpers/form/sanitize';
import { ApiProviderResponse } from '@app/types/api';

export const notifyMe = async (
    form: HTMLFormElement
): Promise<ApiProviderResponse> => {
    const data = new FormData(form);
    const body = sanitizeFormData(data);
    let res: Response;

    try {
        res = await fetch(`${contactNotifyMeUrl}`, {
            body: JSON.stringify(body),
            method: 'POST'
        });
    } catch (err) {
        console.error(err);
        return {
            error: {
                code: res.status,
                message: res.statusText
            },
            success: false
        };
    }

    if (!res.ok) {
        return {
            success: false
        };
    }

    return {
        success: true
    };
};
