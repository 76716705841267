import {
    Product,
    ProductOption,
    ProductVariantConnection
} from '@app/types/shopify';

export enum ShopifyProductOptionName {
    size = 'size',
    style = 'style'
}

export const getShopifyProduct = (handle: string, products: Product[] = []) =>
    products.find((product) => product.handle.toLowerCase() === handle);

export const getShopifyProductOption = (
    name: ShopifyProductOptionName,
    options: ProductOption[]
) =>
    options.find(
        (option) => ShopifyProductOptionName[option.name.toLowerCase()] === name
    );

export const getShopifyProductVariantById = (
    merchantId: string,
    variants: ProductVariantConnection
) => {
    const edge = variants.edges.find(({ node }) => node.id === merchantId);
    return edge?.node;
};

export const getShopifyProductVariantByOptionValues = (
    values: { [key: string]: string },
    variants: ProductVariantConnection
) => {
    const edge = variants.edges.find(({ node: { selectedOptions } }) =>
        selectedOptions.every(
            ({ name, value }) => value === values[name.toLowerCase()]
        )
    );
    return edge?.node;
};
