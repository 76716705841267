import classNames from 'classnames';

import {
    IconProps,
    StandardIcon
} from '@app/components/styled/icons/standard-icon';
import { RoundHollow, ThemeColor } from '@app/components/styled/themed';

export { UiSpriteId } from '@app/components/simple/ui-sprite';
export type { IconProps };

export const RoundHollowedIcon = ({ className, ...props }: IconProps) =>
    RoundHollow({
        color: ThemeColor.Current,
        themable: ({ theme }) =>
            StandardIcon({
                ...props,
                className: classNames(className, theme)
            })
    });
