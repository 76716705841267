import { ComponentOptionalProps } from '@loom-js/core';

import {
    Button,
    ButtonProps,
    UiSprite,
    UiSpriteId
} from '@app/components/simple';

export const ArrowDownButton = (
    props: Omit<ComponentOptionalProps, 'children'> & ButtonProps
) =>
    Button({
        ...props,
        children: UiSprite({ svgId: UiSpriteId.ArrowDown })
    });
