import { component } from '@loom-js/core';
import classNames from 'classnames';

import { menuActiveState } from '@app/activities';
import { Footer } from '@app/components/containers/footer';
import { LayoutHeader } from '@app/components/containers/layout-header';
import { AddToCartModal } from '@app/components/product/containers/modals/add-to-cart-modal';
import { ReactiveCart } from '@app/components/reactive/reactive-cart';
import { AppRoutes } from '@app/components/reactive/routes/app-routes';
import { setHeadSeo } from '@app/helpers/seo/set-head-seo';
import { camelCase } from '@app/helpers/string/camel-case';
import { useProducts } from '@app/hooks/use-products';
import '@app/styles/base.scss';

import styles from './styles.scss';

export const App = component((html, { onCreated, onUnmounted }) => {
    const { update: toggleMenu, value: isMenuOpen } = menuActiveState;
    // Handles scroll-away site navigation.
    const scrollAwayNav = () => isMenuOpen() && toggleMenu(false);
    const setupApp = async () => {
        await useProducts();
        setHeadSeo();
    };

    // Set up event listeners.
    onCreated(() => window.addEventListener('scroll', scrollAwayNav, true));
    onUnmounted(() =>
        // Clean up event listeners.
        window.removeEventListener('scroll', scrollAwayNav, true)
    );
    setupApp();

    return html`
        <div id="app" class=${styles.app}>
            ${LayoutHeader()}
            ${AppRoutes(({ categoryName }) => ({
                className: classNames(
                    styles.product,
                    styles[camelCase(`product-${categoryName}`)]
                )
            }))}
            ${Footer()}${ReactiveCart()}${AddToCartModal()}
        </div>
    `;
});
