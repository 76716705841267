import { UiSprite, UiSpriteId } from '@app/components/simple/ui-sprite';
import { ButtonProps, IconCta } from '@app/components/styled/buttons/icon-cta';

export const CloseCta = (props: Omit<ButtonProps, 'children'>) =>
    IconCta({
        ...props,
        aria: { label: 'Close' },
        children: UiSprite({
            svgId: UiSpriteId.X
        })
    });
